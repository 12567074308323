/* eslint-disable */
import type { Signer } from '@ethersproject/abstract-signer'
import type { Provider } from '@ethersproject/providers'
import { provider } from 'utils/wagmi'
import { Contract } from '@ethersproject/contracts'
import poolsConfig from 'config/constants/pools'
import { PoolCategory } from 'config/constants/types'
import { NOAH1 } from '@noahswap/tokens'

// Addresses
import {
  getAddress,
  getNoahProfileAddress,
  getNoahBunniesAddress,
  getBunnyFactoryAddress,
  getBunnySpecialAddress,
  getLotteryV2Address,
  getMasterChefAddress,
  getMasterChefV1Address,
  getPointCenterIfoAddress,
  getClaimRefundAddress,
  getTradingCompetitionAddressEaster,
  getEasterNftAddress,
  getNoahVaultAddress,
  getMulticallAddress,
  getBunnySpecialNoahVaultAddress,
  getBunnySpecialPredictionAddress,
  getBunnySpecialLotteryAddress,
  getFarmAuctionAddress,
  getAnniversaryAchievement,
  getNftMarketAddress,
  getNftSaleAddress,
  getNoahSquadAddress,
  getTradingCompetitionAddressFanToken,
  getTradingCompetitionAddressMobox,
  getTradingCompetitionAddressMoD,
  getBunnySpecialXmasAddress,
  getINoahAddress,
  getPotteryDrawAddress,
  getZapAddress,
  getNoahFlexibleSideVaultAddress,
  getPredictionsV1Address,
  getBNoahFarmBoosterAddress,
  getBNoahFarmBoosterProxyFactoryAddress,
  getAirdropAddress,
  getBribeFactoryAddress,
  getMinterAddress,
  getRewardsDistributorAddress,
  getSmartWalletCheckerAddress,
  getVesterAddress,
  getVoterAddress,
  getVotingEscrowAddress,
  getFeeVaultAddress,
  getGaugeFactoryAddress,
  getEsnoahAddress,
  getNoahAddress,
} from 'utils/addressHelpers'

// ABI
import profileABI from 'config/abi/noahProfile.json'
import noahBunniesAbi from 'config/abi/noahBunnies.json'
import bunnyFactoryAbi from 'config/abi/bunnyFactory.json'
import bunnySpecialAbi from 'config/abi/bunnySpecial.json'
import bep20Abi from 'config/abi/erc20.json'
import erc721Abi from 'config/abi/erc721.json'
import lpTokenAbi from 'config/abi/lpToken.json'
import noahAbi from 'config/abi/noah.json'
import ifoV1Abi from 'config/abi/ifoV1.json'
import ifoV2Abi from 'config/abi/ifoV2.json'
import pointCenterIfo from 'config/abi/pointCenterIfo.json'
import lotteryV2Abi from 'config/abi/lotteryV2.json'
import masterChef from 'config/abi/masterchef.json'
import masterChefV1 from 'config/abi/masterchefV1.json'
import sousChef from 'config/abi/sousChef.json'
import sousChefV2 from 'config/abi/sousChefV2.json'
import sousChefEos from 'config/abi/sousChefEos.json'
import claimRefundAbi from 'config/abi/claimRefund.json'
import tradingCompetitionEasterAbi from 'config/abi/tradingCompetitionEaster.json'
import tradingCompetitionFanTokenAbi from 'config/abi/tradingCompetitionFanToken.json'
import tradingCompetitionMoboxAbi from 'config/abi/tradingCompetitionMobox.json'
import tradingCompetitionMoDAbi from 'config/abi/tradingCompetitionMoD.json'
import easterNftAbi from 'config/abi/easterNft.json'
import noahVaultV2Abi from 'config/abi/noahVaultV2.json'
import noahFlexibleSideVaultV2Abi from 'config/abi/noahFlexibleSideVaultV2.json'
import predictionsAbi from 'config/abi/predictions.json'
import predictionsV1Abi from 'config/abi/predictionsV1.json'
import chainlinkOracleAbi from 'config/abi/chainlinkOracle.json'
import MultiCallAbi from 'config/abi/Multicall.json'
// import bunnySpecialNoahVaultAbi from 'config/abi/bunnySpecialNoahVault.json'
import bunnySpecialPredictionAbi from 'config/abi/bunnySpecialPrediction.json'
import bunnySpecialLotteryAbi from 'config/abi/bunnySpecialLottery.json'
import bunnySpecialXmasAbi from 'config/abi/bunnySpecialXmas.json'
import farmAuctionAbi from 'config/abi/farmAuction.json'
import anniversaryAchievementAbi from 'config/abi/anniversaryAchievement.json'
import nftMarketAbi from 'config/abi/nftMarket.json'
import nftSaleAbi from 'config/abi/nftSale.json'
import noahSquadAbi from 'config/abi/noahSquad.json'
import erc721CollectionAbi from 'config/abi/erc721collection.json'
import potteryVaultAbi from 'config/abi/potteryVaultAbi.json'
import potteryDrawAbi from 'config/abi/potteryDrawAbi.json'
import zapAbi from 'config/abi/zap.json'
import iNoahAbi from 'config/abi/iNoah.json'
import ifoV3Abi from 'config/abi/ifoV3.json'
import noahPredictionsAbi from 'config/abi/noahPredictions.json'
import bNoahFarmBoosterAbi from 'config/abi/bNoahFarmBooster.json'
import bNoahFarmBoosterProxyFactoryAbi from 'config/abi/bNoahFarmBoosterProxyFactory.json'
import bNoahProxyAbi from 'config/abi/bNoahProxy.json'
import airdropAbi from 'config/abi/airdrop.json'
import BribeFactoryAbi from 'config/abi/BribeFactory.json'
import FeeVaultAbi from 'config/abi/FeeVault.json'
import GaugeFactoryAbi from 'config/abi/GaugeFactory.json'
import MinterAbi from 'config/abi/Minter.json'
import RewardsDistributorAbi from 'config/abi/RewardsDistributor.json'
import SmartWalletCheckerAbi from 'config/abi/SmartWalletChecker.json'
import VesterAbi from 'config/abi/Vester.json'
import VoterAbi from 'config/abi/Voter.json'
import VotingEscrowAbi from 'config/abi/VotingEscrow.json'
import EsNoahAbi from 'config/abi/EsNoah.json'
import { useRouter } from 'next/router'

// Types
import type {
  ChainlinkOracle,
  FarmAuction,
  Predictions,
  AnniversaryAchievement,
  IfoV1,
  IfoV2,
  Erc20,
  Erc721,
  Noah,
  BunnyFactory,
  NoahBunnies,
  NoahProfile,
  LotteryV2,
  Masterchef,
  MasterchefV1,
  SousChef,
  SousChefV2,
  BunnySpecial,
  LpToken,
  ClaimRefund,
  TradingCompetitionEaster,
  TradingCompetitionFanToken,
  EasterNft,
  Multicall,
  // BunnySpecialNoahVault,
  BunnySpecialPrediction,
  BunnySpecialLottery,
  NftMarket,
  NftSale,
  NoahSquad,
  Erc721collection,
  PointCenterIfo,
  NoahVaultV2,
  NoahFlexibleSideVaultV2,
  TradingCompetitionMobox,
  INoah,
  TradingCompetitionMoD,
  PotteryVaultAbi,
  PotteryDrawAbi,
  Zap,
  PredictionsV1,
  BNoahFarmBooster,
  BNoahFarmBoosterProxyFactory,
  BNoahProxy,
} from 'config/abi/types'
import { ChainId } from '@noahswap/sdk'

export const getContract = ({
  abi,
  address,
  // chainId = ChainId.EVM_NET,
  // chainId = ChainId.EVM_TESTNET,
  signer,
  chainId,
}: {
  abi: any
  address: string
  chainId?: any
  signer?: Signer | Provider
}) => {
  // console.log(chainId, 'chainId00000000000')
  const signerOrProvider = signer ?? provider({ chainId })
  return new Contract(address, abi, signerOrProvider)
}

export const getBep20Contract = (address: string, signer?: Signer | Provider) => {
  return getContract({ abi: bep20Abi, address, signer }) as Erc20
}
export const getErc721Contract = (address: string, signer?: Signer | Provider) => {
  return getContract({ abi: erc721Abi, address, signer }) as Erc721
}
export const getLpContract = (address: string, signer?: Signer | Provider) => {
  return getContract({ abi: lpTokenAbi, address, signer }) as LpToken
}
export const getIfoV1Contract = (address: string, signer?: Signer | Provider) => {
  return getContract({ abi: ifoV1Abi, address, signer }) as IfoV1
}
export const getIfoV2Contract = (address: string, signer?: Signer | Provider) => {
  return getContract({ abi: ifoV2Abi, address, signer }) as IfoV2
}
export const getIfoV3Contract = (address: string, signer?: Signer | Provider) => {
  return getContract({ abi: ifoV3Abi, address, signer })
}
export const getSouschefContract = (id: number, signer?: Signer | Provider) => {
  const config = poolsConfig.find((pool) => pool.sousId === id)
  const abi = config.poolCategory === PoolCategory.BINANCE ? sousChefEos : sousChef
  return getContract({ abi, address: getAddress(config.contractAddress), signer }) as SousChef
}
export const getSouschefV2Contract = (id: number, signer?: Signer | Provider) => {
  const config = poolsConfig.find((pool) => pool.sousId === id)
  return getContract({ abi: sousChefV2, address: getAddress(config.contractAddress), signer }) as SousChefV2
}

export const getPointCenterIfoContract = (signer?: Signer | Provider) => {
  return getContract({ abi: pointCenterIfo, address: getPointCenterIfoAddress(), signer }) as PointCenterIfo
}
export const getNoahContract = (signer?: Signer | Provider, chainId?: number) => {
  console.log(NOAH1[chainId], 'NOAH1[chainId]')
  return getContract({
    abi: noahAbi,
    address: NOAH1[chainId].address,
    signer,
  }) as Noah
}
export const getProfileContract = (signer?: Signer | Provider) => {
  return getContract({ abi: profileABI, address: getNoahProfileAddress(), signer }) as NoahProfile
}
export const getNoahBunniesContract = (signer?: Signer | Provider) => {
  return getContract({ abi: noahBunniesAbi, address: getNoahBunniesAddress(), signer }) as NoahBunnies
}
export const getBunnyFactoryContract = (signer?: Signer | Provider) => {
  return getContract({ abi: bunnyFactoryAbi, address: getBunnyFactoryAddress(), signer }) as BunnyFactory
}
export const getBunnySpecialContract = (signer?: Signer | Provider) => {
  return getContract({ abi: bunnySpecialAbi, address: getBunnySpecialAddress(), signer }) as BunnySpecial
}
export const getLotteryV2Contract = (signer?: Signer | Provider) => {
  return getContract({ abi: lotteryV2Abi, address: getLotteryV2Address(), signer }) as LotteryV2
}
export const getMasterchefContract = (signer?: Signer | Provider, chainId?: number) => {
  return getContract({ abi: masterChef, address: getMasterChefAddress(chainId), signer }) as Masterchef
}
export const getMasterchefV1Contract = (signer?: Signer | Provider) => {
  return getContract({ abi: masterChefV1, address: getMasterChefV1Address(), signer }) as MasterchefV1
}
export const getClaimRefundContract = (signer?: Signer | Provider) => {
  return getContract({ abi: claimRefundAbi, address: getClaimRefundAddress(), signer }) as ClaimRefund
}
export const getTradingCompetitionContractEaster = (signer?: Signer | Provider) => {
  return getContract({
    abi: tradingCompetitionEasterAbi,
    address: getTradingCompetitionAddressEaster(),
    signer,
  }) as TradingCompetitionEaster
}

export const getTradingCompetitionContractFanToken = (signer?: Signer | Provider) => {
  return getContract({
    abi: tradingCompetitionFanTokenAbi,
    address: getTradingCompetitionAddressFanToken(),
    signer,
  }) as TradingCompetitionFanToken
}
export const getTradingCompetitionContractMobox = (signer?: Signer | Provider) => {
  return getContract({
    abi: tradingCompetitionMoboxAbi,
    address: getTradingCompetitionAddressMobox(),
    signer,
  }) as TradingCompetitionMobox
}

export const getTradingCompetitionContractMoD = (signer?: Signer | Provider) => {
  return getContract({
    abi: tradingCompetitionMoDAbi,
    address: getTradingCompetitionAddressMoD(),
    signer,
  }) as TradingCompetitionMoD
}

export const getEasterNftContract = (signer?: Signer | Provider) => {
  return getContract({ abi: easterNftAbi, address: getEasterNftAddress(), signer }) as EasterNft
}
export const getNoahVaultV2Contract = (signer?: Signer | Provider) => {
  return getContract({ abi: noahVaultV2Abi, address: getNoahVaultAddress(), signer }) as NoahVaultV2
}

export const getNoahFlexibleSideVaultV2Contract = (signer?: Signer | Provider) => {
  return getContract({
    abi: noahFlexibleSideVaultV2Abi,
    address: getNoahFlexibleSideVaultAddress(),
    signer,
  }) as NoahFlexibleSideVaultV2
}

export const getPredictionsContract = (address: string, signer?: Signer | Provider) => {
  return getContract({ abi: predictionsAbi, address, signer }) as Predictions
}

export const getPredictionsV1Contract = (signer?: Signer | Provider) => {
  return getContract({ abi: predictionsV1Abi, address: getPredictionsV1Address(), signer }) as PredictionsV1
}

export const getNoahPredictionsContract = (address: string, signer?: Signer | Provider) => {
  return getContract({ abi: noahPredictionsAbi, address, signer }) as Predictions
}

export const getChainlinkOracleContract = (address: string, signer?: Signer | Provider) => {
  return getContract({ abi: chainlinkOracleAbi, address, signer }) as ChainlinkOracle
}
export const getMulticallContract = (chainId: ChainId) => {
  return getContract({ abi: MultiCallAbi, address: getMulticallAddress(chainId), chainId }) as Multicall
}
// export const getBunnySpecialNoahVaultContract = (signer?: Signer | Provider) => {
//   return getContract({
//     abi: bunnySpecialNoahVaultAbi,
//     address: getBunnySpecialNoahVaultAddress(),
//     signer,
//   }) as BunnySpecialNoahVault
// }
export const getBunnySpecialPredictionContract = (signer?: Signer | Provider) => {
  return getContract({
    abi: bunnySpecialPredictionAbi,
    address: getBunnySpecialPredictionAddress(),
    signer,
  }) as BunnySpecialPrediction
}
export const getBunnySpecialLotteryContract = (signer?: Signer | Provider) => {
  return getContract({
    abi: bunnySpecialLotteryAbi,
    address: getBunnySpecialLotteryAddress(),
    signer,
  }) as BunnySpecialLottery
}
export const getBunnySpecialXmasContract = (signer?: Signer | Provider) => {
  return getContract({ abi: bunnySpecialXmasAbi, address: getBunnySpecialXmasAddress(), signer })
}
export const getFarmAuctionContract = (signer?: Signer | Provider) => {
  return getContract({ abi: farmAuctionAbi, address: getFarmAuctionAddress(), signer }) as unknown as FarmAuction
}
export const getAnniversaryAchievementContract = (signer?: Signer | Provider) => {
  return getContract({
    abi: anniversaryAchievementAbi,
    address: getAnniversaryAchievement(),
    signer,
  }) as AnniversaryAchievement
}

export const getNftMarketContract = (signer?: Signer | Provider) => {
  return getContract({ abi: nftMarketAbi, address: getNftMarketAddress(), signer }) as NftMarket
}
export const getNftSaleContract = (signer?: Signer | Provider) => {
  return getContract({ abi: nftSaleAbi, address: getNftSaleAddress(), signer }) as NftSale
}
export const getNoahSquadContract = (signer?: Signer | Provider) => {
  return getContract({ abi: noahSquadAbi, address: getNoahSquadAddress(), signer }) as NoahSquad
}
export const getErc721CollectionContract = (signer?: Signer | Provider, address?: string) => {
  return getContract({ abi: erc721CollectionAbi, address, signer }) as Erc721collection
}

export const getPotteryVaultContract = (address: string, signer?: Signer | Provider) => {
  return getContract({ abi: potteryVaultAbi, address, signer }) as PotteryVaultAbi
}

export const getPotteryDrawContract = (signer?: Signer | Provider) => {
  return getContract({ abi: potteryDrawAbi, address: getPotteryDrawAddress(), signer }) as PotteryDrawAbi
}

export const getZapContract = (signer?: Signer | Provider) => {
  return getContract({ abi: zapAbi, address: getZapAddress(), signer }) as Zap
}

export const getIfoCreditAddressContract = (signer?: Signer | Provider) => {
  return getContract({ abi: iNoahAbi, address: getINoahAddress(), signer }) as INoah
}

export const getBNoahFarmBoosterContract = (signer?: Signer | Provider) => {
  return getContract({ abi: bNoahFarmBoosterAbi, address: getBNoahFarmBoosterAddress(), signer }) as BNoahFarmBooster
}

export const getBNoahFarmBoosterProxyFactoryContract = (signer?: Signer | Provider) => {
  return getContract({
    abi: bNoahFarmBoosterProxyFactoryAbi,
    address: getBNoahFarmBoosterProxyFactoryAddress(),
    signer,
  }) as BNoahFarmBoosterProxyFactory
}

export const getBNoahProxyContract = (proxyContractAddress: string, signer?: Signer | Provider) => {
  return getContract({ abi: bNoahProxyAbi, address: proxyContractAddress, signer }) as BNoahProxy
}

// airdrop
export const getAirdropContract = (chainId, signer?: Signer | Provider) => {
  return getContract({ abi: airdropAbi, address: getAirdropAddress(chainId), signer, chainId })
}
export const getBribeFactoryContract = (chainId, signer?: Signer | Provider) => {
  return getContract({ abi: BribeFactoryAbi, address: getBribeFactoryAddress(chainId), signer, chainId })
}
export const getMinterContract = (chainId, signer?: Signer | Provider) => {
  return getContract({ abi: MinterAbi, address: getMinterAddress(chainId), signer, chainId })
}

export const getRewardsDistributorContract = (chainId, signer?: Signer | Provider) => {
  return getContract({ abi: RewardsDistributorAbi, address: getRewardsDistributorAddress(chainId), signer, chainId })
}

export const getSmartWalletCheckerContract = (chainId, signer?: Signer | Provider) => {
  return getContract({ abi: SmartWalletCheckerAbi, address: getSmartWalletCheckerAddress(chainId), signer, chainId })
}

export const getVesterContract = (chainId, signer?: Signer | Provider) => {
  return getContract({ abi: VesterAbi, address: getVesterAddress(chainId), signer, chainId })
}

export const getVoterContract = (chainId, signer?: Signer | Provider) => {
  return getContract({ abi: VoterAbi, address: getVoterAddress(chainId), signer, chainId })
}

export const getVotingEscrowContract = (chainId, signer?: Signer | Provider) => {
  return getContract({ abi: VotingEscrowAbi, address: getVotingEscrowAddress(chainId), signer, chainId })
}

export const getFeeVaultContract = (chainId, signer?: Signer | Provider) => {
  return getContract({ abi: FeeVaultAbi, address: getFeeVaultAddress(chainId), signer, chainId })
}

export const getGaugeFactoryContract = (chainId, signer?: Signer | Provider) => {
  return getContract({ abi: GaugeFactoryAbi, address: getGaugeFactoryAddress(chainId), signer })
}

export const getEsnoahContract = (chainId, signer?: Signer | Provider) => {
  return getContract({ abi: EsNoahAbi, address: getEsnoahAddress(chainId), signer, chainId })
}

export const getNoahContract1 = (chainId, signer?: Signer | Provider) => {
  return getContract({ abi: noahAbi, address: getNoahAddress(chainId), signer, chainId })
}
