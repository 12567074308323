/* eslint-disable */
import { axiosInstance, IResponse } from './index'

export type QueryFn = (params: any) => Promise<IResponse>

/**
 * @description: 获取首页每日快照列表
 * @params
 * @return {Promise}
 */
export const get1dSnapshot = (params: any, { baseURL, chainid }): Promise<IResponse> => {
  // @ts-ignore
  axiosInstance.defaults.headers.chainid = chainid || 17777
  axiosInstance.defaults.baseURL = baseURL || 'https://api.noahark.pro/api/swap'
  return axiosInstance.post('/swap/get1dSnapshot', params).then((res) => res.data)
}

export const getRecords = (params: any, { baseURL, chainid }: any = {}): Promise<IResponse> => {
  // @ts-ignore
  axiosInstance.defaults.headers.chainid = chainid || 17777
  axiosInstance.defaults.baseURL = baseURL || 'https://api.noahark.pro/api/swap'
  return axiosInstance.post('/swap/getRecords', params).then((res) => res.data)
}

/**
 * @description: 获取市场行情
 * @params
 * @return {Promise}
 */
export const getMarket = (params: any, { baseURL, chainid }: any = {}): Promise<IResponse> => {
  // @ts-ignore
  axiosInstance.defaults.headers.chainid = chainid || 17777
  axiosInstance.defaults.baseURL = baseURL || 'https://api.noahark.pro/api/swap'
  return axiosInstance.post('/swap/getMarket', params).then((res) => res.data)
}

/**
 * @description: 单个币种行情
 * @params
 * @return {Promise}
 */
export const getVolumeTrend = (params: any, { baseURL, chainid }: any = {}): Promise<IResponse> => {
  // @ts-ignore
  axiosInstance.defaults.headers.chainid = chainid || 17777
  axiosInstance.defaults.baseURL = baseURL || 'https://api.noahark.pro/api/swap'
  return axiosInstance.post('/swap/getVolumeTrend', params).then((res) => res.data)
}

/**
 * @description: 获取推荐行情
 * @params
 * @return {Promise}
 */
export const getRecommendCoin = ({ baseURL, chainid }: any = {}): Promise<IResponse> => {
  // @ts-ignore
  axiosInstance.defaults.headers.chainid = chainid || 17777
  axiosInstance.defaults.baseURL =
    baseURL || (chainid == 7200 ? 'https://api.noahark.pro/api/exsat/swap' : 'https://api.noahark.pro/api/swap')
  return axiosInstance.post(chainid == 7200 ? '/exsat/swap/getCoinList' : '/swap/getCoinList').then((res) => res?.data)
}

// export const getRecommendExsatCoin = ({ baseURL, chainid }: any = {}): Promise<IResponse> => {
//   // @ts-ignore
//   axiosInstance.defaults.headers.chainid = chainid || 17777
//   axiosInstance.defaults.baseURL = baseURL || 'https://api.noahark.pro/api/exsat/swap'
//   return axiosInstance.post('/exsat/swap/getCoinList').then((res) => res?.data)
// }
/**
 * @description: 获取贿赂币种
 * @params
 * @return {Promise}
 */
export const getGuageCoinList = ({ baseURL, chainid }: any = {}): Promise<IResponse> => {
  // @ts-ignore
  axiosInstance.defaults.headers.chainid = chainid || 17777
  axiosInstance.defaults.baseURL = baseURL || 'https://api.noahark.pro/api/swap'
  return axiosInstance.post('/gauge/getCoinList').then((res) => res?.data)
}

/**
 * @description: 获取evmns
 * @params
 * @return {Promise}
 */
export const getName = (params: any, { baseURL, chainid }: any = {}): Promise<IResponse> => {
  // @ts-ignore
  axiosInstance.defaults.headers.chainid = chainid || 17777
  axiosInstance.defaults.baseURL =
    baseURL || (chainid == 7200 ? 'https://api.noahark.pro/api/exsat/evmns' : 'https://api.noahark.pro/api/evmns')
  return axiosInstance
    .post(chainid == 7200 ? '/exsat/evmns/getName' : '/evmns/getName', params)
    .then((res) => res?.data)
  // axiosInstance.defaults.baseURL = baseURL || 'https://api.noahark.pro/api/swap'
  // return axiosInstance.post('/evmns/getName', params).then((res) => res?.data)
}

/**
 * @description: 24小时数据
 * @params
 * @return {Promise}
 */
export const get24HInfo = ({ baseURL, chainid }: any = {}): Promise<IResponse> => {
  // @ts-ignore
  axiosInstance.defaults.headers.chainid = chainid || 17777
  axiosInstance.defaults.baseURL =
    baseURL || (chainid == 7200 ? 'https://api.noahark.pro/api/exsat/swap' : 'https://api.noahark.pro/api/swap')
  return axiosInstance.get(chainid == 7200 ? '/exsat/swap/get24HInfo' : '/swap/get24HInfo').then((res) => res?.data)
}

/**
 * @description: eos兑换价格
 * @params
 * @return {Promise}
 */
export const getCommonCoinPrice = ({ baseURL, chainid }: any = {}): Promise<IResponse> => {
  // @ts-ignore
  axiosInstance.defaults.headers.chainid = chainid || 17777
  axiosInstance.defaults.baseURL = baseURL || 'https://api.noahark.pro/api/swap'
  return axiosInstance.get('/swap/getCommonCoinPrice').then((res) => res.data)
}

/**
 * @description: 是否展示K线
 * @params
 * @return {Promise}
 */
export const getKlinePairs = (params: any, { baseURL, chainid }: any = {}): Promise<IResponse> => {
  // @ts-ignore
  axiosInstance.defaults.headers.chainid = chainid || 17777
  axiosInstance.defaults.baseURL = baseURL || 'https://api.noahark.pro/api/swap'
  return axiosInstance.post('/swap/getKlinePairs', params).then((res) => res.data)
}

/**
 * @description: 获取空投信息
 * @params
 * @return {Promise}
 */
export const getAirDropInfo = ({ baseURL, chainid, account }: any = {}): Promise<IResponse> => {
  // @ts-ignore
  axiosInstance.defaults.headers.chainid = chainid || 17777
  axiosInstance.defaults.baseURL = baseURL || 'https://api.noahark.pro/api/swap'
  axiosInstance.defaults.headers['account'] = account || ''

  return axiosInstance.get('/swap/getAirDropInfo').then((res) => res?.data)
}

export const getTokenHoldersSnapshotRank = (
  params: any,
  { baseURL, chainid, account }: any = {},
): Promise<IResponse> => {
  // @ts-ignore
  axiosInstance.defaults.headers.chainid = chainid || 17777
  axiosInstance.defaults.baseURL = baseURL || 'https://api.noahark.pro/api/swap'
  axiosInstance.defaults.headers['account'] = account || ''
  return axiosInstance.post('/swap/getTokenHoldersSnapshotRank', params).then((res) => res?.data)
}
/**
 * @description: 获取投票数据
 * @params
 * @return {Promise}
 */
export const getVotePools = ({ baseURL, chainid }: any = {}): Promise<IResponse> => {
  // @ts-ignore
  axiosInstance.defaults.headers.chainid = chainid || 17777
  axiosInstance.defaults.baseURL = baseURL || 'https://api.noahark.pro/api/gauge'
  return axiosInstance.get('/gauge/getVotePools').then((res) => res.data)
}

/**
 * @description: 获取农场数据
 * @params
 * @return {Promise}
 */
export const getFarmPools = ({ baseURL, chainid }: any = {}): Promise<IResponse> => {
  // @ts-ignore
  axiosInstance.defaults.headers.chainid = chainid || 17777
  axiosInstance.defaults.baseURL = baseURL || 'https://api.noahark.pro/api/gauge'
  return axiosInstance.get('/gauge/getFarmPools').then((res) => res.data)
}

/**
 * @description: 获取当前期数
 * @params
 * @return {Promise}
 */
export const getEpoch = ({ baseURL, chainid }: any = {}): Promise<IResponse> => {
  // @ts-ignore
  axiosInstance.defaults.headers.chainid = chainid || 17777
  axiosInstance.defaults.baseURL = baseURL || 'https://api.noahark.pro/api/gauge'
  return axiosInstance.get('/gauge/getEpoch').then((res) => res.data)
}

/**
 * @description: 获取投票奖励列表
 * @params
 * @return {Promise}
 */
export const getRewards = ({ baseURL, chainid, account }: any = {}): Promise<IResponse> => {
  // @ts-ignore
  axiosInstance.defaults.headers.chainid = chainid || 17777
  axiosInstance.defaults.baseURL = baseURL || 'https://api.noahark.pro/api/gauge'
  axiosInstance.defaults.headers['account'] = account || ''
  return axiosInstance.get('/gauge/getRewards').then((res) => res.data)
}

/**
 * @description: 获取投票历史周期数
 * @params
 * @return {Promise}
 */
export const getHistoryEpochs = (params: any, { baseURL, chainid }: any = {}): Promise<IResponse> => {
  // @ts-ignore
  axiosInstance.defaults.headers.chainid = chainid || 17777
  axiosInstance.defaults.baseURL = baseURL || 'https://api.noahark.pro/api/gauge'
  return axiosInstance.post('/gauge/getHistoryEpochs', params).then((res) => res.data)
}

/**
 * @description: 获取投票所在期数数据
 * @params
 * @return {Promise}
 */
export const getHistoryVotePools = (params: any, { baseURL, chainid }: any = {}): Promise<IResponse> => {
  // @ts-ignore
  axiosInstance.defaults.headers.chainid = chainid || 17777
  axiosInstance.defaults.baseURL = baseURL || 'https://api.noahark.pro/api/gauge'
  return axiosInstance.post('/gauge/getHistoryVotePools', params).then((res) => res.data)
}

/**
 * @description: 周数据
 * @params
 * @return {Promise}
 */
export const getWeeklyRewardDistrbutors = (params: any, { baseURL, chainid }: any = {}): Promise<IResponse> => {
  // @ts-ignore
  axiosInstance.defaults.headers.chainid = chainid || 17777
  axiosInstance.defaults.baseURL = baseURL || 'https://api.noahark.pro/api/gauge'
  return axiosInstance.post('/gauge/getWeeklyRewardDistrbutors', params).then((res) => res.data)
}

/**
 * @description: 质押分布
 * @params
 * @return {Promise}
 */
export const getNoahPledgeTrend = (params: any, { baseURL, chainid }: any = {}): Promise<IResponse> => {
  // @ts-ignore
  axiosInstance.defaults.headers.chainid = chainid || 17777
  axiosInstance.defaults.baseURL = baseURL || 'https://api.noahark.pro/api/gauge'
  return axiosInstance.post('/gauge/getNoahPledgeTrend', params).then((res) => res.data)
}

/**
 * @description: esnoah 销毁、释放、解锁量
 * @params
 * @return {Promise}
 */
export const getesNoahStatistics = (params: any, { baseURL, chainid }: any = {}): Promise<IResponse> => {
  // @ts-ignore
  axiosInstance.defaults.headers.chainid = chainid || 17777
  axiosInstance.defaults.baseURL = baseURL || 'https://api.noahark.pro/api/gauge'
  return axiosInstance.post('/gauge/getesNoahStatistics', params).then((res) => res.data)
}

/**
 * @description: 多签
 * @params
 * @return {Promise}
 */
export const getInfo = ({ baseURL, chainid }: any = {}): Promise<IResponse> => {
  // @ts-ignore
  axiosInstance.defaults.headers.chainid = chainid || 17777
  axiosInstance.defaults.baseURL = baseURL || 'https://api.noahark.pro/api/multisig'
  return axiosInstance.get('/multisig/getInfo').then((res) => res.data)
}

/**
 * @description: 提案列表
 * @params
 * @return {Promise}
 */
export const getProposals = ({ baseURL, chainid }: any = {}): Promise<IResponse> => {
  // @ts-ignore
  axiosInstance.defaults.headers.chainid = chainid || 17777
  axiosInstance.defaults.baseURL = baseURL || 'https://api.noahark.pro/api/multisig'
  return axiosInstance.get('/multisig/getProposals').then((res) => res.data)
}

/**
 * @description: 多签详情
 * @params
 * @return {Promise}
 */
export const getProposalDetail = (params: any, { baseURL, chainid }: any = {}): Promise<IResponse> => {
  // @ts-ignore
  axiosInstance.defaults.headers.chainid = chainid || 17777
  axiosInstance.defaults.baseURL = baseURL || 'https://api.noahark.pro/api/multisig'
  return axiosInstance.get('/multisig/getProposalDetail?pid=' + params.pid).then((res) => res.data)
}
