/* eslint-disable */
import { Box, Button, Flex, InjectedModalProps, LinkExternal, Message, Skeleton, Text } from '@noahswap/uikit'
import { ChainId } from '@noahswap/sdk'
import { FetchStatus } from 'config/constants/types'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useTranslation } from '@noahswap/localization'
import useAuth from 'hooks/useAuth'
import useNativeCurrency from 'hooks/useNativeCurrency'
import { useGetNoahBalance } from 'hooks/useTokenBalance'
import { ChainLogo } from 'components/Logo/ChainLogo'

import { getBlockExploreLink, getBlockExploreName } from 'utils'
import { formatBigNumber } from 'utils/formatBalance'
import { useBalance } from 'wagmi'
import styled from 'styled-components'
import { copyText } from 'utils/copyText'
// import CopyAddress from './CopyAddress'
import { CopyButton } from '../../CopyButton'
import WalletTransactions from './WalletTransactions'
import { useEffect, useState } from 'react'
import chainUtils from 'service/chainUtils'
import { getName } from 'service/service'

const IconRadius = styled.div`
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #2fdd8a;
  margin-right: 10px;
`

const Wrap = styled.div``

const WrapStyle = styled.div`
  // border: 1px solid #e5e5e5;
  padding: 8px 0px 10px;
  border-radius: 10px;
  margin-bottom: 10px;
`

const BoxStyle = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #000;
  margin-right: 10px;
`
interface WalletInfoProps {
  hasLowNativeBalance: boolean
  onDismiss: InjectedModalProps['onDismiss']
}

const WalletInfo: React.FC<WalletInfoProps> = ({ hasLowNativeBalance, onDismiss }) => {
  const { t } = useTranslation()
  const { account, chainId, connector, isConnected } = useActiveWeb3React()
  const { logout } = useAuth()

  const handleLogout = () => {
    onDismiss?.()
    logout()
  }

  const accountEllipsis = account ? `${account.substring(0, 6)}...${account.substring(account.length - 4)}` : null
  const [accountName, setAccountName] = useState('')
  // getName
  useEffect(() => {
    const { baseURL, chainid } = chainUtils(chainId)
    const fetch = async () => {
      const params = {
        address: account,
      }
      const { code, data } = await getName(params, { baseURL, chainid })
      if (!code) {
        if (data) {
          setAccountName(data)
        } else {
          setAccountName('')
        }
      }
    }
    fetch()
  }, [account, chainId])
  return (
    <Wrap>
      <WrapStyle>
        <Flex justifyContent="space-between" alignItems="center">
          {isConnected ? (
            <Flex alignItems="center" style={{ fontSize: '14px' }}>
              <div style={{ width: '5px', height: '15px', background: '#FFE169', fontWeight: 'bold' }}></div>
              &nbsp;&nbsp;{t('noah3')} {connector?.name}
              {/* &nbsp;&nbsp;<IconRadius /> */}
            </Flex>
          ) : (
            <></>
          )}
        </Flex>
        {accountName && (
          <div style={{ margin: '20px 0 -20px', width: '100%', fontSize: '20px', fontWeight: 'bold' }}>
            {accountName}
          </div>
        )}
        <Flex style={{ marginTop: '20px', width: '100%', height: '30px' }}>
          {/* <BoxStyle /> */}
          <Flex justifyContent="space-between" alignItems="center" style={{ width: '100%' }}>
            <Flex alignItems="center">
              {accountName ? (
                <Text mr="10px" style={{ fontSize: '14px', color: '#85868A' }}>
                  {accountEllipsis}
                </Text>
              ) : (
                <Text mr="10px" style={{ fontSize: '20px', fontWeight: 'bold' }}>
                  {accountEllipsis}
                </Text>
              )}

              <Flex>
                <Flex>
                  <CopyButton
                    buttonColor="#000"
                    iconColor={true}
                    width="16px"
                    text={account}
                    tooltipMessage={t('Copied')}
                    tooltipTop={185}
                    tooltipRight={210}
                  />
                  {/* <Button variant="text" onClick={() => copyText(account)} style={{ color: "#2766F2", fontSize: "14px", paddingLeft: '5px' }}>复制地址</Button> */}
                </Flex>
                {/* <LinkExternal
                  href={getBlockExploreLink(account, 'address', chainId)}
                  color="#2766F2"
                  style={{ fontSize: '14px', fontWeight: 400 }}
                  showIcon={false}
                  iconShow
                >
                </LinkExternal> */}
              </Flex>
            </Flex>
            <Button onClick={handleLogout} width="100px" height="30px" style={{ fontSize: '14px' }}>
              {t('noah4')}
            </Button>
          </Flex>
        </Flex>
      </WrapStyle>
      <WalletTransactions />
    </Wrap>
  )
}

export default WalletInfo
