/* eslint-disable */
import { parseUnits } from '@ethersproject/units'
import {
  ButtonMenu,
  ButtonMenuItem,
  CloseIcon,
  Heading,
  IconButton,
  InjectedModalProps,
  ModalBody,
  ModalContainer,
  ModalTitle,
  useMatchBreakpoints,
  Text,
  Flex,
  useModal,
} from '@noahswap/uikit'
import { useWeb3React } from '@noahswap/wagmi'
import { useState, useMemo } from 'react'
import { useTranslation } from '@noahswap/localization'
import styled from 'styled-components'
import { ChainLogo } from 'components/Logo/ChainLogo'
import { chains, isChainSupported } from 'utils/wagmi'
import { useActiveChainId } from 'hooks/useActiveChainId'
import { useSwitchNetwork } from 'hooks/useSwitchNetwork'
import { useNetworkConnectorUpdater } from 'hooks/useActiveWeb3React'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import useToast from 'hooks/useToast'
import { useRouter, NextRouter } from 'next/router'
// eslint-disable-next-line import/no-cycle
import RpcModal from './RpcModal'

export const ModalHeader = styled.div<{ background?: string }>`
  align-items: center;
  background: transparent;
  display: flex;
  padding: 12px 24px;
`

const SelectBtn = styled.div`
  display: flex;
  padding: 19px;
  font-size: 20px;
  border: 1px solid #eeeeee;
  border-radius: 6px;
  margin-bottom: 10px;
  cursor: pointer;

  &.active {
    border-color: #000;
    background: #ffe169;
  }
`
const BottomDiv = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #eeeeee;
  width: 100%;
  height: 120px;
  padding: 20px 24px;
`

const BottomBtn = styled.div`
  display: inline-block;
  padding: 6px 20px;
  font-size: 12px;
  border: 1px solid #000;
  border-radius: 6px;
  cursor: pointer;
  margin-top: 10px;
`
const getHashFromRouter = (router: NextRouter) => {
  return router.asPath.match(/#([a-z0-9]+)/gi)
}
const NetworkModal: React.FC<React.PropsWithChildren<InjectedModalProps>> = ({ onDismiss }) => {
  const { t } = useTranslation()
  // const { chainId, isWrongNetwork, isNotMatched } = useActiveChainId()
  const router = useRouter()
  // const parsedQueryChainId = Number(router.query.chainId)
  const parsedQueryChain = router.query.chain
  // const chainId = parsedQueryChainId || Number(localStorage.getItem('chainId')) || 17777
  const { chainId } = useActiveWeb3React()
  // const { pendingChainId, isLoading, switchNetworkAsync } = useSwitchNetwork()
  const { switchNetworkAsync, isLoading, canSwitch } = useSwitchNetwork()
  const { toastError } = useToast()

  const [onPresentRpcModal] = useModal(<RpcModal />)

  // useNetworkConnectorUpdater()
  // console.log('chainId', chainId)
  // console.log('当前网络', parsedQueryChain)
  if (parsedQueryChain === 'exSat') {
    console.log('当前网络', parsedQueryChain)
  }
  // useNetworkConnectorUpdater()
  // 这段代码功能未知, 暂时注释
  // if (parsedQueryChainId !== chainId && isChainSupported(chainId)) {
  //   const uriHash = getHashFromRouter(router)?.[0]

  //   router.replace(
  //     {
  //       query: {
  //         ...router.query,
  //         chainId,
  //       },
  //       ...(uriHash && { hash: uriHash }),
  //     },
  //     undefined,
  //   )
  // }

  const { isMobile } = useMatchBreakpoints()

  const handleClick = async (chain) => {
    console.log('handleClick', chain)
    if (chain.id !== chainId) {
      try {
        // localStorage.setItem('chainId', chain.id)
        localStorage.removeItem('rpcUrl')
        // const uriHash = getHashFromRouter(router)?.[0]
        // console.log(uriHash, 'uriHash')
        // const returnData = await switchNetworkAsync(chain.id)
        // if (returnData) {
        // const href = window.location.href.indexOf('chainId') > -1

        // console.log('href', href)
        // if (href) {
        //   if (router.pathname.indexOf('info/pools') > -1) {
        //     window.location.href = `${window.location.origin}/quotation`
        //   } else {
        //     window.location.href = `${window.location.href.split('chainId')[0]}chainId=${chain.id}`
        //   }
        // }
        // } else {

        // }
        // }
        if (canSwitch) {
          switchNetworkAsync(chain.id)
        }
        onDismiss()
        // window.location.reload()
      } catch (error) {
        toastError(t('Error connecting, please retry and confirm in wallet!'))
      }
    }
  }

  const handleCheckRpc = () => {
    onPresentRpcModal()
  }

  return (
    <ModalContainer title={t('noah183')} $minWidth={isMobile ? '100%' : '418px'}>
      <ModalHeader>
        <ModalTitle>
          <Heading>{t('noah183')}</Heading>
        </ModalTitle>
        <IconButton variant="text" onClick={onDismiss}>
          <CloseIcon width="24px" color="#000" />
        </IconButton>
      </ModalHeader>
      <ModalBody p="0 24px 24px 24px" width="100%">
        {chains.map((chain) => (
          <SelectBtn
            className={chain.id === chainId ? 'active' : undefined}
            key={chain.id}
            style={{ justifyContent: 'flex-start' }}
            onClick={() => handleClick(chain)}
          >
            <ChainLogo chainId={chain.id} />
            <Text fontSize={20} ml={12}>
              {chain.name}
            </Text>
          </SelectBtn>
        ))}
        {/* <BottomDiv>
          <Flex>
            <Text color="#999" fontSize="12px">
              {t('noah221')}
            </Text>
          </Flex>
          <BottomBtn onClick={() => handleCheckRpc()}>{t('noah222')}</BottomBtn>
        </BottomDiv> */}
      </ModalBody>
    </ModalContainer>
  )
}

export default NetworkModal
