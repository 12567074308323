import { ChainId } from '@noahswap/sdk'
import addresses from 'config/constants/contracts'
import { Address } from 'config/constants/types'
import { VaultKey } from 'state/types'

export const getAddress = (address: any, chainId?: number): string => {
  return address[chainId] ? address[chainId] : address[ChainId.EVM]
}

export const getMasterChefAddress = (chainId?: number) => {
  return getAddress(addresses.masterChef, chainId)
}
export const getMasterChefV1Address = () => {
  return getAddress(addresses.masterChefV1)
}
export const getMulticallAddress = (chainId?: number) => {
  return getAddress(addresses.multiCall, chainId)
}
export const getLotteryV2Address = () => {
  return getAddress(addresses.lotteryV2)
}
export const getNoahProfileAddress = () => {
  return getAddress(addresses.noahProfile)
}
export const getNoahBunniesAddress = () => {
  return getAddress(addresses.noahBunnies)
}
export const getBunnyFactoryAddress = () => {
  return getAddress(addresses.bunnyFactory)
}
export const getPredictionsV1Address = () => {
  return getAddress(addresses.predictionsV1)
}
export const getClaimRefundAddress = () => {
  return getAddress(addresses.claimRefund)
}
export const getPointCenterIfoAddress = () => {
  return getAddress(addresses.pointCenterIfo)
}
export const getBunnySpecialAddress = () => {
  return getAddress(addresses.bunnySpecial)
}
export const getTradingCompetitionAddressEaster = () => {
  return getAddress(addresses.tradingCompetitionEaster)
}
export const getTradingCompetitionAddressFanToken = () => {
  return getAddress(addresses.tradingCompetitionFanToken)
}

export const getTradingCompetitionAddressMobox = () => {
  return getAddress(addresses.tradingCompetitionMobox)
}

export const getTradingCompetitionAddressMoD = () => {
  return getAddress(addresses.tradingCompetitionMoD)
}

export const getEasterNftAddress = () => {
  return getAddress(addresses.easterNft)
}

export const getVaultPoolAddress = (vaultKey: VaultKey) => {
  if (!vaultKey) {
    return null
  }
  return getAddress(addresses[vaultKey])
}

export const getNoahVaultAddress = () => {
  return getAddress(addresses.noahVault)
}

export const getNoahFlexibleSideVaultAddress = () => {
  return getAddress(addresses.noahFlexibleSideVault)
}

export const getBunnySpecialNoahVaultAddress = () => {
  return getAddress(addresses.bunnySpecialNoahVault)
}
export const getBunnySpecialPredictionAddress = () => {
  return getAddress(addresses.bunnySpecialPrediction)
}
export const getBunnySpecialLotteryAddress = () => {
  return getAddress(addresses.bunnySpecialLottery)
}
export const getBunnySpecialXmasAddress = () => {
  return getAddress(addresses.bunnySpecialXmas)
}
export const getFarmAuctionAddress = () => {
  return getAddress(addresses.farmAuction)
}
export const getAnniversaryAchievement = () => {
  return getAddress(addresses.AnniversaryAchievement)
}

export const getNftMarketAddress = () => {
  return getAddress(addresses.nftMarket)
}
export const getNftSaleAddress = () => {
  return getAddress(addresses.nftSale)
}
export const getNoahSquadAddress = () => {
  return getAddress(addresses.noahSquad)
}
export const getPotteryDrawAddress = () => {
  return getAddress(addresses.potteryDraw)
}

export const getZapAddress = () => {
  return getAddress(addresses.zap)
}
export const getINoahAddress = () => {
  return getAddress(addresses.iNoah)
}

export const getBNoahFarmBoosterAddress = () => {
  return getAddress(addresses.bNoahFarmBooster)
}

export const getBNoahFarmBoosterProxyFactoryAddress = () => {
  return getAddress(addresses.bNoahFarmBoosterProxyFactory)
}

export const getAirdropAddress = (chainId) => {
  return getAddress(addresses.airdrop, chainId)
}

export const getBribeFactoryAddress = (chainId) => {
  return getAddress(addresses.bribeFactory, chainId)
}

export const getMinterAddress = (chainId) => {
  return getAddress(addresses.Minter, chainId)
}

export const getRewardsDistributorAddress = (chainId) => {
  return getAddress(addresses.RewardsDistributor, chainId)
}

export const getSmartWalletCheckerAddress = (chainId) => {
  return getAddress(addresses.SmartWalletChecker, chainId)
}

export const getVesterAddress = (chainId) => {
  return getAddress(addresses.Vester, chainId)
}

export const getVoterAddress = (chainId) => {
  return getAddress(addresses.Voter, chainId)
}

export const getVotingEscrowAddress = (chainId) => {
  return getAddress(addresses.VotingEscrow, chainId)
}

export const getFeeVaultAddress = (chainId) => {
  return getAddress(addresses.FeeVault, chainId)
}

export const getGaugeFactoryAddress = (chainId) => {
  return getAddress(addresses.GaugeFactory, chainId)
}

export const getEsnoahAddress = (chainId) => {
  return getAddress(addresses.Esnoah, chainId)
}

export const getNoahAddress = (chainId) => {
  return getAddress(addresses.Noah, chainId)
}
