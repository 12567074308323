/* eslint-disable */
import { createGlobalStyle } from 'styled-components'
import { NoahTheme } from '@noahswap/uikit'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends NoahTheme {}
}

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Inter custom',sans-serif;
  }
  body {
    background-color: ${({ theme }) => theme.colors.background};

    img {
      height: auto;
      max-width: 100%;
    }
  }
  .btn{
    cursor: pointer;
  }
  .pointer{
    cursor: pointer;
  }
  .flex{
    display: flex;
  }
  .flexb{
    display: flex;
    justify-content: space-between;
  }
  .flexc{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .flexstart{
    display: flex;
    justify-content: start;
  }
  .flexend{
    display: flex;
    justify-content: end;
    align-items: center;
  }
  .flexcol{
    flex-direction: column;
  }
  .flexac{
    align-items: center;
  }
  .flexjc{
    justify-content: center;
  }
  .iconfont{
    width: 12px;
    height: 12px;
  }
  .no_data {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 200px;
  }
`

export default GlobalStyle
