/* eslint-disable */
import { TokenList } from '@uniswap/token-lists'
import { ChainId, Token } from '@noahswap/sdk'
import { WrappedTokenInfo, TagInfo, TokenAddressMap, EMPTY_LIST } from '@noahswap/tokens'
import uniqBy from 'lodash/uniqBy'
import groupBy from 'lodash/groupBy'
import fromPairs from 'lodash/fromPairs'
import { useEffect, useState } from 'react'
import { getRecommendCoin } from 'service/service'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import chainUtils from 'service/chainUtils'
import { useRouter } from 'next/router'
import DEFAULT_TOKEN_LIST from '../config/constants/tokenLists/noah-default.tokenlist.json'
import useUserAddedTokens from '../state/user/hooks/useUserAddedTokens'

const listCache: WeakMap<TokenList, TokenAddressMap> | null =
  typeof WeakMap !== 'undefined' ? new WeakMap<TokenList, TokenAddressMap>() : null

function enumKeys<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
  return Object.keys(obj).filter((k) => Number.isNaN(+k)) as K[]
}

function listToTokenMap(list: TokenList): TokenAddressMap {
  // const result = listCache?.get(list)
  // if (result) return results
  const tokenMap: WrappedTokenInfo[] = uniqBy(
    list.tokens,
    (tokenInfo) => `${tokenInfo.chainId}#${tokenInfo.address}`,
  ).map((tokenInfo) => {
    const tags: TagInfo[] =
      tokenInfo.tags
        ?.map((tagId) => {
          if (!list.tags?.[tagId]) return undefined
          return { ...list.tags[tagId], id: tagId }
        })
        ?.filter((x): x is TagInfo => Boolean(x)) ?? []
    return new WrappedTokenInfo(tokenInfo, tags)
  })
  const groupedTokenMap: { [chainId: string]: WrappedTokenInfo[] } = groupBy(tokenMap, (tokenInfo) => tokenInfo.chainId)
  // console.log(groupedTokenMap, 'groupedTokenMapgroupedTokenMap')
  const tokenAddressMap = fromPairs(
    Object.entries(groupedTokenMap).map(([chainId, tokenInfoList]) => [
      chainId,
      fromPairs(tokenInfoList.map((tokenInfo) => [tokenInfo.address, { token: tokenInfo, list }])),
    ]),
  ) as TokenAddressMap
  // add chain id item if not exist
  enumKeys(ChainId).forEach((chainId) => {
    if (!(ChainId[chainId] in tokenAddressMap)) {
      Object.defineProperty(tokenAddressMap, ChainId[chainId], {
        value: {},
      })
    }
  })

  listCache?.set(list, tokenAddressMap)
  return tokenAddressMap
}

const mapWithoutUrls = (tokenMap: TokenAddressMap, chainId: number) =>
  Object.keys((tokenMap && tokenMap[chainId]) || {}).reduce<{ [address: string]: Token }>((newMap, address) => {
    // eslint-disable-next-line no-param-reassign
    newMap[address] = tokenMap[chainId][address].token
    return newMap
  }, {})

export function useCommonTokens() {
  // console.log('执行了useCommonTokens')
  // const { chainId } = useActiveWeb3React()
  const router = useRouter()
  const parsedQueryChainId = Number(router.query.chainId)
  const chainId = parsedQueryChainId || Number(localStorage.getItem('chainId')) || 17777
  // console.log(parsedQueryChainId, 'parsedQueryChainId')
  // console.log(Number(localStorage.getItem('chainId')), 'Number(localStorage.getItem)')

  // console.log(chainId, 'chainIdchainIdchainId')

  // const userAddedTokens = useUserAddedTokens()
  const [listData, setListData] = useState<any>({})
  // console.log(chainId, 'chainIdchainId')
  useEffect(() => {
    const { baseURL, chainid } = chainUtils(chainId)
    const fetch = async () => {
      const defaultList = DEFAULT_TOKEN_LIST
      const { data, code } = await getRecommendCoin({ baseURL, chainid })
      let formatData = []
      if (!code) {
        formatData =
          data?.map((v) => {
            const vv = v
            const { decimal } = vv
            delete vv.decimal
            return {
              ...vv,
              decimals: decimal,
              chainId: vv.chain_id,
              logoURI: '',
              address: vv.contract,
            }
          }) || []
        defaultList.tokens = [...formatData]
        const defaultTokenMap = listToTokenMap(defaultList)

        // const listDatas = userAddedTokens
        //   // reduce into all ALL_TOKENS filtered by the current chain
        //   .reduce<{ [address: string]: Token }>(
        //     (tokenMap_, token) => {
        //       // eslint-disable-next-line no-param-reassign
        //       tokenMap_[token.address] = token
        //       return tokenMap_
        //     },
        //     // must make a copy because reduce modifies the map, and we do not
        //     // want to make a copy in every iteration
        //     mapWithoutUrls(defaultTokenMap, chainId),
        //   )
        const listDatas = mapWithoutUrls(defaultTokenMap, chainId)
        setListData(listDatas)
      }
    }
    fetch()
  }, [chainId])
  // console.log(listData, 'listDatalistData')
  return listData || {}
}
