import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  position: fixed;\n  top: 0px;\n  left: 0px;\n  width: 100%;\n  height: 100%;\n  background-color: #452a7a;\n  transition: opacity 0.4s;\n  opacity: ",
        ";\n  z-index: ",
        ";\n  pointer-events: ",
        ";\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
var Overlay = styled.div.attrs({
    role: "presentation"
}).withConfig({
    componentId: "sc-4173224c-0"
})(_templateObject(), function(param) {
    var show = param.show;
    return show ? 0.6 : 0;
}, function(param) {
    var zIndex = param.zIndex;
    return zIndex;
}, function(param) {
    var show = param.show;
    return show ? "initial" : "none";
});
Overlay.defaultProps = {
    show: false,
    zIndex: 10
};
export default Overlay;
