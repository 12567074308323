import { ConnectorNames } from '@noahswap/uikit'
import { useCallback, useMemo } from 'react'
import { useTranslation } from '@noahswap/localization'
import replaceBrowserHistory from 'utils/replaceBrowserHistory'
import { useAccount, useSwitchNetwork as useSwitchNetworkWallet } from 'wagmi'
import { ChainId } from '@noahswap/sdk'
import { useNetworkConnectorUpdater } from 'hooks/useActiveWeb3React'
import { useSessionChainId } from './useSessionChainId'
import { useSwitchNetworkLoading } from './useSwitchNetworkLoading'
import useToast from './useToast'

export function useSwitchNetworkLocal() {
  const [, setSessionChainId] = useSessionChainId()
  return useCallback(
    (chainId: number) => {
      setSessionChainId(chainId)
      // replaceBrowserHistory('chainId', chainId === ChainId.EVM_TESTNET ? null : chainId)
      if (chainId === ChainId.EXSAT_MAINNET) {
        replaceBrowserHistory('chain', 'exSat')
      } else if (chainId === ChainId.EVM_TESTNET) {
        replaceBrowserHistory('chain', 'evm_TESTNET')
      } else if (chainId === ChainId.EVM) {
        replaceBrowserHistory('chain', 'evm')
      }
    },
    [setSessionChainId],
  )
}

export function useSwitchNetwork() {
  const [loading, setLoading] = useSwitchNetworkLoading()
  const {
    switchNetworkAsync: _switchNetworkAsync,
    isLoading: _isLoading,
    switchNetwork: _switchNetwork,
    ...switchNetworkArgs
  } = useSwitchNetworkWallet()
  const { t } = useTranslation()
  const { toastError } = useToast()
  const { isConnected, connector } = useAccount()

  const switchNetworkLocal = useSwitchNetworkLocal()

  const switchNetworkAsync = useCallback(
    async (chainId: number) => {
      if (isConnected && typeof _switchNetworkAsync === 'function') {
        setLoading(true)
        return _switchNetworkAsync(chainId)
          .then(() => {
            window.location.href = `${window.location.origin}/`
            if (typeof window !== 'undefined') {
              localStorage.setItem('chainId', chainId.toString())
            }
          })
          .catch(() => {
            // TODO: review the error
            toastError(t('Error connecting, please retry and confirm in wallet!'))
          })
          .finally(() => setLoading(false))
      }
      return new Promise(() => {
        switchNetworkLocal(chainId)
        window.location.href = `${window.location.origin}/`
        if (typeof window !== 'undefined') {
          localStorage.setItem('chainId', chainId.toString())
        }
      })
    },
    [isConnected, _switchNetworkAsync, t, setLoading, toastError, switchNetworkLocal],
  )

  const switchNetwork = useCallback(
    (chainId: number) => {
      if (isConnected && typeof _switchNetwork === 'function') {
        return _switchNetwork(chainId)
      }
      return switchNetworkLocal(chainId)
    },
    [_switchNetwork, isConnected, switchNetworkLocal],
  )

  const isLoading = _isLoading || loading
  const canSwitch = useMemo(
    () =>
      isConnected
        ? !!_switchNetworkAsync &&
          connector.id !== ConnectorNames.WalletConnect &&
          !(
            typeof window !== 'undefined' &&
            // @ts-ignore // TODO: add type later
            window.ethereum?.isSafePal
          )
        : true,
    [_switchNetworkAsync, isConnected, connector],
  )

  return {
    ...switchNetworkArgs,
    switchNetwork,
    switchNetworkAsync,
    isLoading,
    canSwitch,
  }
}
