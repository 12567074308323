import { Token, ChainId } from '@noahswap/sdk'
import { evmTokens } from '@noahswap/tokens'
import { NOAH_EOS_LP_MAINNET } from './lp'
import { Ifo } from './types'

export const noahEosLpToken = new Token(ChainId.EVM, NOAH_EOS_LP_MAINNET, 18, 'NOAH-EOS LP')

const ifos: Ifo[] = []

export default ifos
