import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { dark as darkAlert } from "../components/Alert/theme";
import { dark as darkCard } from "../components/Card/theme";
import { dark as darkNoahToggle } from "../components/NoahToggle/theme";
import { dark as darkRadio } from "../components/Radio/theme";
import { dark as darkToggle } from "../components/Toggle/theme";
import { dark as darkNav } from "../widgets/Menu/theme";
import { dark as darkModal } from "../widgets/Modal/theme";
import { dark as darkTooltip } from "../components/Tooltip/theme";
import base from "./base";
import { darkColors } from "./colors";
var darkTheme = _object_spread_props(_object_spread({}, base), {
    isDark: true,
    alert: darkAlert,
    colors: darkColors,
    card: darkCard,
    toggle: darkToggle,
    nav: darkNav,
    modal: darkModal,
    noahToggle: darkNoahToggle,
    radio: darkRadio,
    tooltip: darkTooltip
});
export default darkTheme;
