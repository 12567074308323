import {
  MenuItemsType,
  DropdownMenuItemType,
  SwapIcon,
  SwapFillIcon,
  EarnFillIcon,
  EarnIcon,
  MoreIcon,
} from '@noahswap/uikit'
import { ContextApi } from '@noahswap/localization'
import { DropdownMenuItems } from '@noahswap/uikit/src/components/DropdownMenu/types'
// import { SUPPORT_ONLY_EVM } from 'config/constants/supportChains'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean; image?: string } & {
  items?: ConfigMenuDropDownItemsType[]
}

const addMenuItemSupported = (item, chainId) => {
  if (!chainId || !item.supportChainIds) {
    return item
  }
  if (item.supportChainIds?.includes(chainId)) {
    return item
  }
  return {
    ...item,
    disabled: true,
  }
}

const config: (
  t: ContextApi['t'],
  isDark: boolean,
  languageCode?: string,
  chainId?: number,
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) =>
  [
    // {
    //   label: t('Trade'),
    //   icon: SwapIcon,
    //   fillIcon: SwapFillIcon,
    //   href: '/swap',
    //   showItemsOnMobile: false,
    //   items: [
    //     {
    //       label: t('noah11'),
    //       href: '/swap',
    //     },
    //     {
    //       label: t('noah37'),
    //       href: '/liquidity',
    //     },
    //   ].map((item) => addMenuItemSupported(item, chainId)),
    // },
    {
      label: t('noah11'),
      icon: SwapIcon,
      fillIcon: SwapFillIcon,
      href: '/swap',
      showItemsOnMobile: false,
      items: [],
    },
    {
      label: t('noah37'),
      icon: SwapIcon,
      fillIcon: SwapFillIcon,
      href: '/liquidity',
      showItemsOnMobile: false,
      items: [],
    },
    {
      label: t('Farms'),
      href: '/farms',
      icon: EarnIcon,
      fillIcon: EarnFillIcon,
      // image: '/images/decorations/pe2.png',
      showItemsOnMobile: false,
      // supportChainIds: SUPPORT_ONLY_EVM,
      items: [],
      // items: [
      // {
      //   label: t('Farms'),
      //   href: '/farms',
      // },
      // {
      //   label: t('Pools'),
      //   href: '/pools',
      //   supportChainIds: SUPPORT_ONLY_EVM,
      // },
      // ].map((item) => addMenuItemSupported(item, chainId)),
    },
    {
      label: '',
      href: '/info',
      icon: MoreIcon,
      hideSubNav: true,
      items: [
        {
          label: t('Info'),
          href: '/info',
          supportChainIds: false,
        },
        // {
        //   label: t('IFO'),
        //   href: '/ifo',
        //   supportChainIds: SUPPORT_ONLY_EVM,
        //   image: '/images/ifos/ifo-bunny.png',
        // },
        // {
        //   label: t('Voting'),
        //   href: '/voting',
        //   supportChainIds: SUPPORT_ONLY_EVM,
        //   image: '/images/voting/voting-bunny.png',
        // },
        {
          type: DropdownMenuItemType.DIVIDER,
        },
        // {
        //   label: t('Leaderboard'),
        //   href: '/teams',
        //   supportChainIds: SUPPORT_ONLY_EVM,
        //   image: '/images/decorations/leaderboard.png',
        // },
        {
          type: DropdownMenuItemType.DIVIDER,
        },
        {
          label: t('Blog'),
          href: 'https://medium.com/noahswap',
          type: DropdownMenuItemType.EXTERNAL_LINK,
        },
        {
          label: t('noah102'),
          href: 'https://docs.noahark.pro',
          type: DropdownMenuItemType.EXTERNAL_LINK,
        },
      ].map((item) => addMenuItemSupported(item, chainId)),
    },
  ].map((item) => addMenuItemSupported(item, chainId))

export default config
