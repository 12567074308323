export const GRAPH_API_PROFILE = ''
export const GRAPH_API_PREDICTION_EOS = ''
export const GRAPH_API_PREDICTION_NOAH = ''

export const GRAPH_API_LOTTERY = ''
export const SNAPSHOT_BASE_URL = ''
export const API_PROFILE = ''
export const API_NFT = ''
export const SNAPSHOT_API = ''
export const SNAPSHOT_HUB_API = ''
export const GRAPH_API_POTTERY = ''

/**
 * V1 will be deprecated but is still used to claim old rounds
 */
export const GRAPH_API_PREDICTION_V1 = ''

export const INFO_CLIENT = ''
export const BLOCKS_CLIENT = ''
export const GRAPH_API_NFTMARKET = ''
export const GRAPH_HEALTH = ''

export const TC_MOBOX_SUBGRAPH = ''
export const TC_MOD_SUBGRAPH = ''
