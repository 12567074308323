import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  0%,\n  100% {\n    background-position: 0 0;\n  }\n  50% {\n    background-position: 100% 0;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  color: ",
        ";\n  flex-grow: 1;\n  /* &:hover {\n    color: #000;\n  } */\n  height: ",
        "px;\n  line-height: ",
        "px;\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  width: 180px;\n  margin: 0 auto;\n  height: ",
        "px;\n  padding: ",
        ";\n  font-size: ",
        ";\n  background-color: ",
        ";\n  border-radius: 15px;\n  margin-bottom: 10px;\n  /* box-shadow: ",
        "; */\n\n  a {\n    display: flex;\n    align-items: center;\n    width: 100%;\n    height: 100%;\n  }\n\n  svg {\n    fill: gray;\n  }\n\n  &:hover {\n    /* background-color: ",
        "; */\n  }\n\n  // Safari fix\n  flex-shrink: 0;\n\n  &.rainbow {\n    background-clip: text;\n    animation: ",
        " 3s ease-in-out infinite;\n    background: ",
        ";\n    background-size: 400% 100%;\n  }\n  &:hover {\n    // border: 1px solid red;\n    /* background: #69696a; */\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
import React from "react";
import styled, { keyframes } from "styled-components";
import { MENU_ENTRY_HEIGHT } from "../config";
var rainbowAnimation = keyframes(_templateObject());
var LinkLabel = styled.div.withConfig({
    componentId: "sc-df5fe1b0-0"
})(_templateObject1(), function(param) {
    var isActive = param.isActive;
    return isActive ? "#000" : "#fff";
}, MENU_ENTRY_HEIGHT, MENU_ENTRY_HEIGHT);
var MenuEntry = styled.div.withConfig({
    componentId: "sc-df5fe1b0-1"
})(_templateObject2(), MENU_ENTRY_HEIGHT, function(param) {
    var secondary = param.secondary;
    return secondary ? "0 32px" : "0 16px";
}, function(param) {
    var secondary = param.secondary;
    return secondary ? "14px" : "16px";
}, function(param) {
    var isActive = param.isActive, theme = param.theme;
    return isActive ? theme.colors.tertiary : "transparent";
}, function(param) {
    var isActive = param.isActive, theme = param.theme;
    return isActive ? "inset 4px 0px 0px ".concat(theme.colors.primary) : "none";
}, function(param) {
    var theme = param.theme;
    return theme.colors.tertiary;
}, rainbowAnimation, function(param) {
    var theme = param.theme;
    return theme.colors.gradients.bubblegum;
});
MenuEntry.defaultProps = {
    secondary: false,
    isActive: false,
    role: "button"
};
// const LinkLabelMemo = React.memo(LinkLabel, (prev, next) => prev.isActive === next.isActive);
var LinkLabelMemo = LinkLabel;
export { MenuEntry, LinkLabelMemo as LinkLabel };
