import replaceBrowserHistory from 'utils/replaceBrowserHistory'
import { Box, connectorLocalStorageKey, ConnectorNames, LinkExternal, Text } from '@noahswap/uikit'
import { useTranslation } from '@noahswap/localization'
import { useCallback } from 'react'
import { useAppDispatch } from 'state'
import { useConnect, useDisconnect, useNetwork, ConnectorNotFoundError, UserRejectedRequestError } from 'wagmi'
import { clearUserStates } from '../utils/clearUserStates'
import { useActiveChainId } from './useActiveChainId'
import useToast from './useToast'
import { useSessionChainId } from './useSessionChainId'
// import { InjectedConnector } from 'wagmi/connectors/injected'
// import { evm, evmTest } from '@noahswap/wagmi/chains'

// const CHAINS = [
//   evm,
//   evmTest,
// ]
const useAuth = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { connectAsync, connectors } = useConnect()
  const { chain } = useNetwork()
  const { disconnectAsync } = useDisconnect()
  const { toastError } = useToast()
  const { chainId } = useActiveChainId()
  const [, setSessionChainId] = useSessionChainId()
  // console.log('connectors', connectors)
  const login = useCallback(
    async (connectorID: ConnectorNames) => {
      // console.log('connectorID', connectorID)
      const findConnector = connectors.find((c) => c.id === connectorID)
      // console.log('🏀findConnector', findConnector)
      try {
        const connected = await connectAsync({ connector: findConnector, chainId })
        console.log('Connect🏀🏀', connected)
        if (!connected.chain.unsupported && connected.chain.id !== chainId) {
          replaceBrowserHistory('chainId', connected.chain.id)
          setSessionChainId(connected.chain.id)
        }
      } catch (error) {
        console.error(error)
        window?.localStorage?.removeItem(connectorLocalStorageKey)
        if (error instanceof ConnectorNotFoundError) {
          toastError(
            t('Provider Error'),
            <Box>
              <Text>{t('No provider was found')}</Text>
              <LinkExternal href="https://docs.noahark.pro/get-started/connection-guide">
                {t('Need help ?')}
              </LinkExternal>
            </Box>,
          )
          return
        }
        if (error instanceof UserRejectedRequestError) {
          return
        }
        if (error instanceof Error) {
          toastError(error.message, t('Please authorize to access your account'))
        }
      }
    },
    [connectors, connectAsync, chainId, setSessionChainId, toastError, t],
  )

  const logout = useCallback(async () => {
    try {
      await disconnectAsync()
    } catch (error) {
      console.error(error)
    } finally {
      clearUserStates(dispatch, chain?.id, true)
    }
  }, [disconnectAsync, dispatch, chain?.id])

  return { login, logout }
}

export default useAuth
