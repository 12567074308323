import {
  Noah,
  NoahFlexibleSideVaultV2,
  NoahVaultV2,
  Erc20,
  Erc20Bytes32,
  Erc721collection,
  Multicall,
  Weth,
  Zap,
} from 'config/abi/types'
import zapAbi from 'config/abi/zap.json'
import Gauge from 'config/abi/Gauge.json'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useProviderOrSigner } from 'hooks/useProviderOrSigner'
import { useMemo } from 'react'
import { getMulticallAddress, getPredictionsV1Address, getZapAddress } from 'utils/addressHelpers'
import {
  getAnniversaryAchievementContract,
  getBNoahFarmBoosterContract,
  getBNoahFarmBoosterProxyFactoryContract,
  getBNoahProxyContract,
  getBep20Contract,
  getBunnyFactoryContract,
  // getBunnySpecialNoahVaultContract,
  getBunnySpecialContract,
  getBunnySpecialLotteryContract,
  getBunnySpecialPredictionContract,
  getBunnySpecialXmasContract,
  getNoahContract,
  getNoahFlexibleSideVaultV2Contract,
  getNoahPredictionsContract,
  getNoahVaultV2Contract,
  getChainlinkOracleContract,
  getClaimRefundContract,
  getEasterNftContract,
  getErc721CollectionContract,
  getErc721Contract,
  getFarmAuctionContract,
  getIfoV1Contract,
  getIfoV2Contract,
  getIfoV3Contract,
  getLotteryV2Contract,
  getMasterchefContract,
  getMasterchefV1Contract,
  getNftMarketContract,
  getNftSaleContract,
  getNoahBunniesContract,
  getNoahSquadContract,
  getPointCenterIfoContract,
  getPotteryDrawContract,
  getPotteryVaultContract,
  getPredictionsContract,
  getPredictionsV1Contract,
  getProfileContract,
  getSouschefContract,
  getTradingCompetitionContractEaster,
  getTradingCompetitionContractFanToken,
  getTradingCompetitionContractMobox,
  getTradingCompetitionContractMoD,
  getAirdropContract,
  getBribeFactoryContract,
  getMinterContract,
  getRewardsDistributorContract,
  getSmartWalletCheckerContract,
  getVesterContract,
  getVoterContract,
  getVotingEscrowContract,
  getFeeVaultContract,
  getGaugeFactoryContract,
  getEsnoahContract,
  getNoahContract1,
} from 'utils/contractHelpers'
import { useSigner } from 'wagmi'

// Imports below migrated from Exchange useContract.ts
import { Contract } from '@ethersproject/contracts'
import { WNATIVE } from '@noahswap/sdk'
import { ERC20_BYTES32_ABI } from '../config/abi/erc20'
import ERC20_ABI from '../config/abi/erc20.json'
import INoahPairABI from '../config/abi/INoahPair.json'
import multiCallAbi from '../config/abi/Multicall.json'
import WETH_ABI from '../config/abi/weth.json'
import { getContract } from '../utils'

import { INoahPair } from '../config/abi/types/INoahPair'
import { VaultKey } from '../state/types'
import { useActiveChainId } from './useActiveChainId'

/**
 * Helper hooks to get specific contracts (by ABI)
 */

export const useIfoV1Contract = (address: string) => {
  const { data: signer } = useSigner()
  return useMemo(() => getIfoV1Contract(address, signer), [address, signer])
}

export const useIfoV2Contract = (address: string) => {
  const { data: signer } = useSigner()
  return useMemo(() => getIfoV2Contract(address, signer), [address, signer])
}

export const useIfoV3Contract = (address: string) => {
  const { data: signer } = useSigner()
  return useMemo(() => getIfoV3Contract(address, signer), [address, signer])
}

export const useERC20 = (address: string, withSignerIfPossible = true) => {
  const providerOrSigner = useProviderOrSigner(withSignerIfPossible)
  return useMemo(() => getBep20Contract(address, providerOrSigner), [address, providerOrSigner])
}

/**
 * @see https://docs.openzeppelin.com/contracts/3.x/api/token/erc721
 */
export const useERC721 = (address: string, withSignerIfPossible = true) => {
  const providerOrSigner = useProviderOrSigner(withSignerIfPossible)
  return useMemo(() => getErc721Contract(address, providerOrSigner), [address, providerOrSigner])
}

export const useNoah = (): { reader: Noah; signer: Noah } => {
  const providerOrSigner = useProviderOrSigner()
  return useMemo(
    () => ({
      reader: getNoahContract(null),
      signer: getNoahContract(providerOrSigner),
    }),
    [providerOrSigner],
  )
}

export const useBunnyFactory = () => {
  const { data: signer } = useSigner()
  return useMemo(() => getBunnyFactoryContract(signer), [signer])
}

export const useNoahBunnies = () => {
  const { data: signer } = useSigner()
  return useMemo(() => getNoahBunniesContract(signer), [signer])
}

export const useProfileContract = (withSignerIfPossible = true) => {
  const providerOrSigner = useProviderOrSigner(withSignerIfPossible)
  return useMemo(() => getProfileContract(providerOrSigner), [providerOrSigner])
}

export const useLotteryV2Contract = () => {
  const providerOrSigner = useProviderOrSigner()
  return useMemo(() => getLotteryV2Contract(providerOrSigner), [providerOrSigner])
}

export const useMasterchef = (withSignerIfPossible = true) => {
  const { chainId } = useActiveChainId()
  const providerOrSigner = useProviderOrSigner(withSignerIfPossible)
  return useMemo(() => getMasterchefContract(providerOrSigner, chainId), [providerOrSigner, chainId])
}

export const useMasterchefV1 = () => {
  const { data: signer } = useSigner()
  return useMemo(() => getMasterchefV1Contract(signer), [signer])
}

export const useSousChef = (id) => {
  const { data: signer } = useSigner()
  return useMemo(() => getSouschefContract(id, signer), [id, signer])
}

export const usePointCenterIfoContract = () => {
  const { data: signer } = useSigner()
  return useMemo(() => getPointCenterIfoContract(signer), [signer])
}

export const useBunnySpecialContract = () => {
  const { data: signer } = useSigner()
  return useMemo(() => getBunnySpecialContract(signer), [signer])
}

export const useClaimRefundContract = () => {
  const { data: signer } = useSigner()
  return useMemo(() => getClaimRefundContract(signer), [signer])
}

export const useTradingCompetitionContractEaster = (withSignerIfPossible = true) => {
  const providerOrSigner = useProviderOrSigner(withSignerIfPossible)
  return useMemo(() => getTradingCompetitionContractEaster(providerOrSigner), [providerOrSigner])
}

export const useTradingCompetitionContractFanToken = (withSignerIfPossible = true) => {
  const providerOrSigner = useProviderOrSigner(withSignerIfPossible)
  return useMemo(() => getTradingCompetitionContractFanToken(providerOrSigner), [providerOrSigner])
}

export const useTradingCompetitionContractMobox = (withSignerIfPossible = true) => {
  const providerOrSigner = useProviderOrSigner(withSignerIfPossible)
  return useMemo(() => getTradingCompetitionContractMobox(providerOrSigner), [providerOrSigner])
}

export const useTradingCompetitionContractMoD = (withSignerIfPossible = true) => {
  const providerOrSigner = useProviderOrSigner(withSignerIfPossible)
  return useMemo(() => getTradingCompetitionContractMoD(providerOrSigner), [providerOrSigner])
}

export const useEasterNftContract = () => {
  const { data: signer } = useSigner()
  return useMemo(() => getEasterNftContract(signer), [signer])
}

export const useVaultPoolContract = (vaultKey: VaultKey): NoahVaultV2 | NoahFlexibleSideVaultV2 => {
  const { data: signer } = useSigner()
  return useMemo(() => {
    if (vaultKey === VaultKey.NoahVault) {
      return getNoahVaultV2Contract(signer)
    }
    if (vaultKey === VaultKey.NoahFlexibleSideVault) {
      return getNoahFlexibleSideVaultV2Contract(signer)
    }
    return null
  }, [signer, vaultKey])
}

export const useNoahVaultContract = (withSignerIfPossible = true) => {
  const providerOrSigner = useProviderOrSigner(withSignerIfPossible)
  return useMemo(() => getNoahVaultV2Contract(providerOrSigner), [providerOrSigner])
}

export const usePredictionsContract = (address: string, tokenSymbol: string) => {
  const { data: signer } = useSigner()
  return useMemo(() => {
    if (address === getPredictionsV1Address()) {
      return getPredictionsV1Contract(signer)
    }
    const getPredContract = tokenSymbol === 'NOAH' ? getNoahPredictionsContract : getPredictionsContract

    return getPredContract(address, signer)
  }, [address, tokenSymbol, signer])
}

export const useChainlinkOracleContract = (address, withSignerIfPossible = true) => {
  const providerOrSigner = useProviderOrSigner(withSignerIfPossible)
  return useMemo(() => getChainlinkOracleContract(address, providerOrSigner), [providerOrSigner, address])
}

// export const useSpecialBunnyNoahVaultContract = () => {
//   const { data: signer } = useSigner()
//   return useMemo(() => getBunnySpecialNoahVaultContract(signer), [signer])
// }

export const useSpecialBunnyPredictionContract = () => {
  const { data: signer } = useSigner()
  return useMemo(() => getBunnySpecialPredictionContract(signer), [signer])
}

export const useBunnySpecialLotteryContract = () => {
  const { data: signer } = useSigner()
  return useMemo(() => getBunnySpecialLotteryContract(signer), [signer])
}

export const useBunnySpecialXmasContract = () => {
  const { data: signer } = useSigner()
  return useMemo(() => getBunnySpecialXmasContract(signer), [signer])
}

export const useAnniversaryAchievementContract = () => {
  const { data: signer } = useSigner()
  return useMemo(() => getAnniversaryAchievementContract(signer), [signer])
}

export const useNftSaleContract = () => {
  const { data: signer } = useSigner()
  return useMemo(() => getNftSaleContract(signer), [signer])
}

export const useNoahSquadContract = () => {
  const { data: signer } = useSigner()
  return useMemo(() => getNoahSquadContract(signer), [signer])
}

export const useFarmAuctionContract = (withSignerIfPossible = true) => {
  const providerOrSigner = useProviderOrSigner(withSignerIfPossible)
  return useMemo(() => getFarmAuctionContract(providerOrSigner), [providerOrSigner])
}

export const useNftMarketContract = () => {
  const { data: signer } = useSigner()
  return useMemo(() => getNftMarketContract(signer), [signer])
}

export const useErc721CollectionContract = (
  collectionAddress: string,
): { reader: Erc721collection; signer: Erc721collection } => {
  const { data: signer } = useSigner()
  return useMemo(
    () => ({
      reader: getErc721CollectionContract(null, collectionAddress),
      signer: getErc721CollectionContract(signer, collectionAddress),
    }),
    [signer, collectionAddress],
  )
}

// Code below migrated from Exchange useContract.ts

// returns null on errors
export function useContract<T extends Contract = Contract>(
  address: string | undefined,
  ABI: any,
  withSignerIfPossible = true,
): T | null {
  const { provider } = useActiveWeb3React()
  const providerOrSigner = useProviderOrSigner(withSignerIfPossible) ?? provider

  const canReturnContract = useMemo(() => address && ABI && providerOrSigner, [address, ABI, providerOrSigner])

  return useMemo(() => {
    if (!canReturnContract) return null
    try {
      return getContract(address, ABI, providerOrSigner)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, ABI, providerOrSigner, canReturnContract]) as T
}

export function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean) {
  return useContract<Erc20>(tokenAddress, ERC20_ABI, withSignerIfPossible)
}

export function useWNativeContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract<Weth>(chainId ? WNATIVE[chainId]?.address : undefined, WETH_ABI, withSignerIfPossible)
}

export function useBytes32TokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract<Erc20Bytes32>(tokenAddress, ERC20_BYTES32_ABI, withSignerIfPossible)
}

export function usePairContract(pairAddress?: string, withSignerIfPossible?: boolean): INoahPair | null {
  return useContract(pairAddress, INoahPairABI, withSignerIfPossible)
}

export function useMulticallContract() {
  const { chainId } = useActiveWeb3React()
  return useContract<Multicall>(getMulticallAddress(chainId), multiCallAbi, false)
}

export const usePotterytVaultContract = (address) => {
  const { data: signer } = useSigner()
  return useMemo(() => getPotteryVaultContract(address, signer), [address, signer])
}

export const usePotterytDrawContract = () => {
  const { data: signer } = useSigner()
  return useMemo(() => getPotteryDrawContract(signer), [signer])
}

export function useZapContract(withSignerIfPossible = true) {
  return useContract<Zap>(getZapAddress(), zapAbi, withSignerIfPossible)
}

export function useBNoahFarmBoosterContract(withSignerIfPossible = true) {
  const providerOrSigner = useProviderOrSigner(withSignerIfPossible)
  return useMemo(() => getBNoahFarmBoosterContract(providerOrSigner), [providerOrSigner])
}

export function useBNoahFarmBoosterProxyFactoryContract(withSignerIfPossible = true) {
  const providerOrSigner = useProviderOrSigner(withSignerIfPossible)
  return useMemo(() => getBNoahFarmBoosterProxyFactoryContract(providerOrSigner), [providerOrSigner])
}

export function useBNoahProxyContract(proxyContractAddress: string, withSignerIfPossible = true) {
  const providerOrSigner = useProviderOrSigner(withSignerIfPossible)
  return useMemo(
    () => proxyContractAddress && getBNoahProxyContract(proxyContractAddress, providerOrSigner),
    [providerOrSigner, proxyContractAddress],
  )
}
// 空投合约
export const useAirdropContract = () => {
  const { data: signer } = useSigner()
  const { chainId } = useActiveChainId()
  return useMemo(() => getAirdropContract(chainId, signer), [chainId, signer])
}

// 创建贿赂工厂合约
export const useBribeFactoryContract = () => {
  const { data: signer } = useSigner()
  const { chainId } = useActiveChainId()
  return useMemo(() => getBribeFactoryContract(chainId, signer), [chainId, signer])
}
// minter: mint_marketing 用于市场铸币 update_team_vc_period team / vc周期铸币  setReleaseFactor 释放系数 setPledgeFactor 质押系数
export const useMinterContract = () => {
  const { data: signer } = useSigner()
  const { chainId } = useActiveChainId()
  return useMemo(() => getMinterContract(chainId, signer), [chainId, signer])
}
// RewardsDistributor： claim claim_many 领取质押noah奖励
export const useRewardsDistributorContract = () => {
  const { data: signer } = useSigner()
  const { chainId } = useActiveChainId()
  return useMemo(() => getRewardsDistributorContract(chainId, signer), [chainId, signer])
}
// 白名单验证
export const useSmartWalletCheckerContract = () => {
  const { data: signer } = useSigner()
  const { chainId } = useActiveChainId()
  return useMemo(() => getSmartWalletCheckerContract(chainId, signer), [chainId, signer])
}
// vester: deposit 质押解锁 withdraw 提取解锁奖励和全部esnoah
export const useVesterContract = () => {
  const { data: signer } = useSigner()
  const { chainId } = useActiveChainId()
  return useMemo(() => getVesterContract(chainId, signer), [chainId, signer])
}
// voter: vote 投票 poke 刷新投票 claimRewards 领取质押奖励 claimBribes 领取贿赂奖励 whitelist 设置token白名单  setVotableGauge 设置可投票池子
export const useVoterContract = () => {
  const { data: signer } = useSigner()
  const { chainId } = useActiveChainId()
  return useMemo(() => getVoterContract(chainId, signer), [chainId, signer])
}
// create_lock increase_amount increase_unlock_time 投票escrow
export const useVotingEscrowContract = () => {
  const { data: signer } = useSigner()
  const { chainId } = useActiveChainId()
  return useMemo(() => getVotingEscrowContract(chainId, signer), [chainId, signer])
}
// 手续费价值
export const useFeeVaultContract = () => {
  const { data: signer } = useSigner()
  const { chainId } = useActiveChainId()
  return useMemo(() => getFeeVaultContract(chainId, signer), [chainId, signer])
}
// gaugeFactory: createGauge 创建池子
export const useGaugeFactoryContract = () => {
  const { data: signer } = useSigner()
  const { chainId } = useActiveChainId()
  return useMemo(() => getGaugeFactoryContract(chainId, signer), [chainId, signer])
}
// gaugeFactory: createGauge 创建池子
export const useEsnoahContract = () => {
  const { data: signer } = useSigner()
  const { chainId } = useActiveChainId()
  return useMemo(() => getEsnoahContract(chainId, signer), [chainId, signer])
}

export const useNoahContract = () => {
  const { data: signer } = useSigner()
  const { chainId } = useActiveChainId()
  return useMemo(() => getNoahContract1(chainId, signer), [chainId, signer])
}

export const useOtherContract = (address, abi) => {
  const { data: signer } = useSigner()
  return useMemo(() => getContract(address, abi, signer), [abi, address, signer])
}
