// import { useMemo } from 'react'
import { useRouter } from 'next/router'
import { BrowserRouter } from 'react-router-dom'
import { NextLinkFromReactRouter } from 'components/NextLink'
import { Menu as UikitMenu } from '@noahswap/uikit'
import { useTranslation, languageList } from '@noahswap/localization'
import { NetworkSwitcher } from 'components/NetworkSwitcher'
import { links } from '@noahswap/uikit/src/components/SlideMenu/config'
import UserMenu from './UserMenu'
import { useMenuItems } from './hooks/useMenuItems'
import { getActiveMenuItem, getActiveSubMenuItem } from './utils'

const Menu = (props) => {
  const { currentLanguage, setLanguage } = useTranslation()
  const { pathname } = useRouter()

  const menuItems = useMenuItems()

  const activeMenuItem = getActiveMenuItem({ menuConfig: menuItems, pathname })
  const activeSubMenuItem = getActiveSubMenuItem({ menuItem: activeMenuItem, pathname })

  return (
    <>
      <BrowserRouter>
        <UikitMenu
          links={links}
          isDark
          currentLang={currentLanguage.code}
          langs={languageList}
          setLang={setLanguage}
          rightSide={
            <>
              {/* <NetworkSwitcher /> */}
              <UserMenu />
            </>
          }
          {...props}
        />
      </BrowserRouter>
    </>
  )
}

export default Menu
