import { BinanceWalletConnector } from '@noahswap/wagmi/connectors/binanceWallet'
import { evm, evmTest, exsat } from '@noahswap/wagmi/chains'
import { configureChains, createClient } from 'wagmi'
import memoize from 'lodash/memoize'
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'
import { SafeConnector } from '@gnosis.pm/safe-apps-wagmi'
import { EVM_PROD_NODE } from 'utils/providers'

// const CHAINS = [
//   // TODO: ETH
//   // mainnet,
//   // evmTest,
//   // rinkeby,
//   // goerli,
//   evm,
//   evmTest,
//   exsat,
//   // evmTest1,
// ]
let CHAINS = [exsat, evm]
if (process.env.NODE_ENV === 'development') {
  CHAINS = [exsat, evm, evmTest]
}
const arr1 = [evmTest, exsat]
const arr2 = [evm, exsat]

// if(process.env.NODE_ENV === 'development'){
//   CHAINS = arr1
// } else {
//   CHAINS = arr2
// }

const getNodeRealUrl = (networkName: string) => {
  let host = null
  switch (networkName) {
    case 'homestead':
      if (process.env.NEXT_PUBLIC_NODE_REAL_API_ETH) {
        host = `eth-mainnet.nodereal.io/v1/${process.env.NEXT_PUBLIC_NODE_REAL_API_ETH}`
      }
      break
    case 'rinkeby':
      if (process.env.NEXT_PUBLIC_NODE_REAL_API_RINKEBY) {
        host = `eth-rinkeby.nodereal.io/v1/${process.env.NEXT_PUBLIC_NODE_REAL_API_RINKEBY}`
      }
      break
    case 'goerli':
      if (process.env.NEXT_PUBLIC_NODE_REAL_API_GOERLI) {
        host = `eth-goerli.nodereal.io/v1/${process.env.NEXT_PUBLIC_NODE_REAL_API_GOERLI}`
      }
      break
    default:
      host = null
  }

  if (!host) {
    return null
  }

  const url = `https://${host}`
  return {
    http: url,
    webSocket: url.replace(/^http/i, 'wss').replace('.nodereal.io/v1', '.nodereal.io/ws/v1'),
  }
}

export const { provider, chains } = configureChains(CHAINS, [
  jsonRpcProvider({
    rpc: (chain) => {
      let rpcUrl = ''
      if (typeof window !== 'undefined') {
        // rpcUrl = window.localStorage.getItem('rpcUrl')
        rpcUrl = EVM_PROD_NODE
      }
      if (chain.id === 17777 && rpcUrl) {
        return { http: rpcUrl }
      }
      // if (!!process.env.NEXT_PUBLIC_NODE_PRODUCTION && chain.id === evm.id) {
      //   return { http: process.env.NEXT_PUBLIC_NODE_PRODUCTION }
      // }
      // if (chain.rpcUrls.nodeReal) {
      //   return (
      //     getNodeRealUrl(chain.network) || {
      //       http: chain.rpcUrls.nodeReal,
      //     }
      //   )
      // }
      return { http: chain.rpcUrls.default }
    },
  }),
])

export const injectedConnector = new InjectedConnector({
  chains,
  options: {
    shimDisconnect: false,
    shimChainChangedDisconnect: true,
  },
})

export const coinbaseConnector = new CoinbaseWalletConnector({
  chains,
  options: {
    appName: 'noahswap',
    appLogoUrl: 'https://noahswap.com/logo.png',
  },
})

export const walletConnectConnector = new WalletConnectConnector({
  chains,
  options: {
    qrcode: true,
  },
})

export const metaMaskConnector = new MetaMaskConnector({
  chains,
  options: {
    shimDisconnect: false,
    shimChainChangedDisconnect: true,
  },
})

export const evmConnector = new BinanceWalletConnector({ chains })

export const client = createClient({
  autoConnect: false,
  provider,
  connectors: [
    new SafeConnector({ chains }),
    metaMaskConnector,
    injectedConnector,
    coinbaseConnector,
    walletConnectConnector,
    evmConnector,
  ],
})

export const CHAIN_IDS = chains.map((c) => c.id)

export const isChainSupported = memoize((chainId: number) => CHAIN_IDS.includes(chainId))
export const isChainTestnet = memoize((chainId: number) => chains.find((c) => c.id === chainId)?.testnet)
