import { ChainId } from '@noahswap/sdk'
import { BIG_TEN } from 'utils/bigNumber'

export const EVM_BLOCK_TIME = 3

export const BASE_EVM_SCAN_URLS = {
  [ChainId.EVM]: 'https://explorer.evm.eosnetwork.com',
  [ChainId.EVM_NET]: 'https://explorer.evm.eosnetwork.com',
  [ChainId.EVM_TESTNET]: 'https://explorer.testnet.evm.eosnetwork.com',
  [ChainId.EXSAT_MAINNET]: ' https://scan.exsat.network',
}

// NOAH_PER_BLOCK details
// 40 NOAH is minted per block
// 20 NOAH per block is sent to Burn pool (A farm just for burning noah)
// 10 NOAH per block goes to NOAH syrup pool
// 9 NOAH per block goes to Yield farms and lottery
// NOAH_PER_BLOCK in config/index.ts = 40 as we only change the amount sent to the burn pool which is effectively a farm.
// NOAH/Block in src/views/Home/components/noahDataRow.tsx = 15 (40 - Amount sent to burn pool)
export const NOAH_PER_BLOCK = 40
export const BLOCKS_PER_YEAR = (60 / EVM_BLOCK_TIME) * 60 * 24 * 365 // 10512000
export const NOAH_PER_YEAR = NOAH_PER_BLOCK * BLOCKS_PER_YEAR
export const BASE_URL = 'https://noahark.pro'
export const BASE_ADD_LIQUIDITY_URL = `${BASE_URL}/add`
export const BASE_EVM_SCAN_URL = BASE_EVM_SCAN_URLS[ChainId.EVM]
export const DEFAULT_TOKEN_DECIMAL = BIG_TEN.pow(18)
export const DEFAULT_GAS_LIMIT = 250000
export const BOOSTED_FARM_GAS_LIMIT = 500000
export const AUCTION_BIDDERS_TO_FETCH = 500
export const RECLAIM_AUCTIONS_TO_FETCH = 500
export const AUCTION_WHITELISTED_BIDDERS_TO_FETCH = 500
export const IPFS_GATEWAY = 'https://ipfs.io/ipfs'

export const ALLOWED_PATHS = ['/', '/swap', '/liquidity', '/add', '/find', '/404', '/451']

export const NETWORK_SPECIFIC_ROUTES: { [chainId: number]: string[] } = {
  7200: ['/', '/swap', '/liquidity', '/add', '/find', '/404', '/451'],
  // 添加其他网络的路由限制
}

export const DEFAULT_CHAINID = 7200
