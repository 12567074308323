var ref, ref1, ref2;
import Metamask from "../../components/Svg/Icons/Metamask";
import WalletConnect from "../../components/Svg/Icons/WalletConnect";
import TrustWallet from "../../components/Svg/Icons/TrustWallet";
import TokenPocket from "../../components/Svg/Icons/TokenPocket";
import Brave from "../../components/Svg/Icons/Brave";
import CoinbaseWallet from "../../components/Svg/Icons/CoinbaseWallet";
import Opera from "../../components/Svg/Icons/Opera";
import { ConnectorNames } from "./types";
var connectors = [
    {
        title: "Metamask",
        icon: Metamask,
        connectorId: ConnectorNames.MetaMask,
        priority: 1,
        href: "https://metamask.app.link/dapp/noahark.io/"
    },
    {
        title: "TokenPocket",
        icon: TokenPocket,
        connectorId: ConnectorNames.Injected,
        // priority: 1,
        priority: function() {
            var ref;
            return "object" !== "undefined" && Boolean((ref = window.ethereum) === null || ref === void 0 ? void 0 : ref.isTokenPocket) ? 0 : 999;
        },
        installed: "object" !== "undefined" && Boolean((ref = window.ethereum) === null || ref === void 0 ? void 0 : ref.isTokenPocket)
    },
    // {
    //   title: "Binance Wallet",
    //   icon: BinanceChain,
    //   connectorId: ConnectorNames.EVM,
    //   priority: 2,
    // },
    {
        title: "WalletConnect",
        icon: WalletConnect,
        connectorId: ConnectorNames.WalletConnect,
        priority: 2
    },
    {
        title: "Trust Wallet",
        icon: TrustWallet,
        connectorId: ConnectorNames.Injected,
        priority: 3,
        href: "https://link.trustwallet.com/open_url?coin_id=20000714&url=https://noahark.io/"
    },
    {
        title: "Coinbase Wallet",
        icon: CoinbaseWallet,
        connectorId: ConnectorNames.WalletLink,
        priority: 4
    },
    {
        title: "Bitkeep Wallet",
        icon: Opera,
        connectorId: ConnectorNames.Injected,
        priority: function() {
            var ref;
            return "object" !== "undefined" && Boolean(window === null || window === void 0 ? void 0 : (ref = window.ethereum) === null || ref === void 0 ? void 0 : ref.isBitKeep) ? 0 : 6;
        },
        installed: "object" !== "undefined" && Boolean(window === null || window === void 0 ? void 0 : (ref1 = window.ethereum) === null || ref1 === void 0 ? void 0 : ref1.isBitKeep),
        href: "https://bitkeep.com/en/download?type=2"
    },
    {
        title: "FoxWallet",
        icon: Brave,
        connectorId: ConnectorNames.Injected,
        priority: function() {
            var ref;
            // @ts-ignore
            return "object" !== "undefined" && Boolean(window === null || window === void 0 ? void 0 : (ref = window.ethereum) === null || ref === void 0 ? void 0 : ref.isFoxwallet) ? 0 : 999;
        },
        // @ts-ignore
        installed: "object" !== "undefined" && Boolean(window === null || window === void 0 ? void 0 : (ref2 = window.ethereum) === null || ref2 === void 0 ? void 0 : ref2.isFoxwallet),
        href: "https://foxwallet.com/download"
    }, 
];
export default connectors;
export var connectorLocalStorageKey = "connectorIdv2";
export var walletLocalStorageKey = "wallet";
export var walletConnectConfig = connectors.find(function(c) {
    return c.title === "WalletConnect";
});
